import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormLayout } from 'components/form/formLayout/formLayout';
import { FormFieldDescription } from 'components/form/formLayout/types';
import { Input, InputType } from 'components/inputs';
import { Switch } from 'components/inputs/switch/switch';

import { AccountSettings } from 'types/account';

import { FORM_FIELDS, LABELS } from '../keys';
import { Adornment } from './styles';

interface GeneralAccountSettingFormProps {
  account: AccountSettings;
  onFieldValueChange: (key: string, value: any) => void;
  disabledFields: Record<string, boolean>;
  isUpdating: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
  isAdmin: boolean;
}

export const GeneralAccountSettingForm = ({
  account,
  onFieldValueChange,
  disabledFields,
  isUpdating,
  error,
  readOnlyMode,
  isAdmin,
}: GeneralAccountSettingFormProps) => {
  const { t } = useTranslation();

  const handleChange = (fieldName: string) => (value: any) => {
    onFieldValueChange(fieldName, value);
  };

  const fields: FormFieldDescription[] = [
    {
      key: FORM_FIELDS.ACCOUNT_ID,
      label: t(LABELS.FORM_FIELDS.ACCOUNT_ID),
      renderInput: () => account.uuid,
    },
    {
      key: FORM_FIELDS.ACCOUNT_NAME,
      label: t(LABELS.FORM_FIELDS.ACCOUNT_NAME),
      renderInput: ({ readonly }) =>
        readonly ? (
          account.name
        ) : (
          <Input
            fullWidth
            value={account.name}
            onChange={handleChange(FORM_FIELDS.ACCOUNT_NAME)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.ACCOUNT_NAME)}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.WEBHOOK_URL,
      label: t(LABELS.FORM_FIELDS.WEBHOOK_URL),
      renderInput: ({ readonly }) =>
        readonly ? (
          `${LABELS.ADORNMENTS.HTTPS}${account.webhookUrl}`
        ) : (
          <Input
            fullWidth
            value={account.webhookUrl}
            onChange={handleChange(FORM_FIELDS.WEBHOOK_URL)}
            startAdornment={<Adornment>{LABELS.ADORNMENTS.HTTPS}</Adornment>}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.ONRAMP,
      label: t(LABELS.FORM_FIELDS.ONRAMP),
      hidden: disabledFields[FORM_FIELDS.ONRAMP],
      renderInput: ({ readonly }) => {
        if (readonly) {
          return t(account.onRampEnabled ? LABELS.FORM_FIELDS.MESSAGES.YES : LABELS.FORM_FIELDS.MESSAGES.NO);
        }

        return <Switch value={account.onRampEnabled} onChange={handleChange(FORM_FIELDS.ONRAMP)} disabled={isUpdating} />;
      },
    },
    {
      key: FORM_FIELDS.OFFRAMP,
      label: t(LABELS.FORM_FIELDS.OFFRAMP),
      hidden: disabledFields[FORM_FIELDS.OFFRAMP],
      renderInput: ({ readonly }) => {
        if (readonly) {
          return t(account.offRampEnabled ? LABELS.FORM_FIELDS.MESSAGES.YES : LABELS.FORM_FIELDS.MESSAGES.NO);
        }

        return <Switch value={account.offRampEnabled} onChange={handleChange(FORM_FIELDS.OFFRAMP)} disabled={isUpdating} />;
      },
    },
    {
      key: FORM_FIELDS.ARE_CUSTOMER_EMAIL_ENABLED,
      label: t(LABELS.FORM_FIELDS.ARE_CUSTOMER_EMAIL_ENABLED),
      hidden: disabledFields[FORM_FIELDS.ARE_CUSTOMER_EMAIL_ENABLED],
      renderInput: ({ readonly }) => {
        if (readonly) {
          return t(account.areCustomerEmailsEnabled ? LABELS.FORM_FIELDS.MESSAGES.YES : LABELS.FORM_FIELDS.MESSAGES.NO);
        }

        return (
          <Switch
            value={account.areCustomerEmailsEnabled}
            onChange={handleChange(FORM_FIELDS.ARE_CUSTOMER_EMAIL_ENABLED)}
            disabled={isUpdating}
          />
        );
      },
    },
    {
      key: FORM_FIELDS.PARTNER_FEE,
      label: t(LABELS.FORM_FIELDS.PARTNER_FEE),
      hidden: disabledFields[FORM_FIELDS.PARTNER_FEE],
      renderInput: ({ readonly }) =>
        readonly || !isAdmin ? (
          t(LABELS.FORM_FIELDS.VALUES.PARTNER_FEE, { fee: account.partnerFee })
        ) : (
          <Input
            fullWidth
            value={account.partnerFee?.toString()}
            onChange={handleChange(FORM_FIELDS.PARTNER_FEE)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.PARTNER_FEE)}
            type={InputType.Number}
            endAdornment={<Adornment>{LABELS.ADORNMENTS.PERCENT}</Adornment>}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: LABELS.FEES_LABEL[0],
      label: t(LABELS.FEES_LABEL),
      fullLabelWidth: true,
      renderInput: () => null,
    },
    {
      key: FORM_FIELDS.PROCESSING_FEE_EEA,
      label: t(LABELS.FORM_FIELDS.PROCESSING_FEE_EEA),
      hidden: disabledFields[FORM_FIELDS.PROCESSING_FEE_EAA],
      renderInput: ({ readonly }) =>
        readonly ? (
          t(LABELS.FORM_FIELDS.VALUES.PROCESSING_FEE_EEA, { fee: account.processingFeeEEA })
        ) : (
          <Input
            fullWidth
            value={account.processingFeeEEA?.toString()}
            onChange={handleChange(FORM_FIELDS.PROCESSING_FEE_EEA)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.PROCESSING_FEE_EAA)}
            type={InputType.Number}
            endAdornment={<Adornment>{LABELS.ADORNMENTS.PERCENT}</Adornment>}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.PROCESSING_FEE,
      label: t(LABELS.FORM_FIELDS.PROCESSING_FEE),
      hidden: disabledFields[FORM_FIELDS.PROCESSING_FEE],
      renderInput: ({ readonly }) =>
        readonly ? (
          t(LABELS.FORM_FIELDS.VALUES.PROCESSING_FEE, { fee: account.processingFee })
        ) : (
          <Input
            fullWidth
            value={account.processingFee?.toString()}
            onChange={handleChange(FORM_FIELDS.PROCESSING_FEE)}
            placeholder={t(LABELS.FORM_FIELDS.PLACEHOLDERS.PROCESSING_FEE)}
            type={InputType.Number}
            endAdornment={<Adornment>{LABELS.ADORNMENTS.PERCENT}</Adornment>}
            disabled={isUpdating}
          />
        ),
    },
    {
      key: FORM_FIELDS.IS_CERTIFIED,
      label: t(LABELS.FORM_FIELDS.IS_CERTIFIED),
      hidden: disabledFields[FORM_FIELDS.IS_CERTIFIED],
      renderInput: () => <Switch value={account.isCertified} onChange={handleChange(FORM_FIELDS.IS_CERTIFIED)} disabled={isUpdating} />,
    },
  ];

  return <FormLayout fields={fields} readonly={readOnlyMode} error={error} />;
};
